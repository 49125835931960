import React from 'react';
import Layout from '../../layouts/admin'

const Admin = () => {
    return (
       <Layout>
           zapytania
       </Layout> 
    )
}

export default Admin